<!-- src/App.vue -->
<template>
  <div id="app">
    <el-container>
      <el-header>
        <el-row type="flex" justify="center">
          <el-col :span="18">
            <el-menu mode="horizontal" router default-active="/">
              <el-menu-item index="/">首页</el-menu-item>
              <el-menu-item index="/product">产品介绍</el-menu-item>
              <el-menu-item index="/team">团队介绍</el-menu-item>
            </el-menu>
          </el-col>
        </el-row>
      </el-header>
      <el-main>
        <router-view></router-view>
      </el-main>
      <el-footer>
        <!-- <p>备案信息：xxxxx</p>
        <p>友情链接：[链接1](https://www.example1.com), [链接2](https://www.example2.com)</p> -->
      </el-footer>
    </el-container>
  </div>
</template>

<script>
export default {
  name: 'App',
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.el-header,
.el-footer {
  background-color: #fff;
  text-align: center;
  padding: 20px;
}

.el-main {
  padding: 20px;
}

.el-footer p {
  margin: 0;
}
</style>