<template>
  <div>
    <h1>产品介绍</h1>
    <p>我们的产品主要是各种小工具</p>
  </div>
</template>

<script>
export default {
  name: 'ProductView',
}
</script>