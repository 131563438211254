<template>
  <div>
    <h1>首页</h1>
    <p>这是我们的公司首页，主要是展示我们的团队</p>
  </div>
</template>

<script>
export default {
  name: 'HomeView',
}
</script>