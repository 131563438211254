<template>
  <div>
    <h1>团队介绍</h1>
    <p>我们的团队主要是来自各种大厂，多年的应用开发及广告系统研发经验</p>
  </div>
</template>

<script>
export default {
  name: 'TeamView',
}
</script>